<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container fluid>
        <div class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <div class="ticketItemContent ticketItemContentAcc">
                <div class="transaction-info">
                  <div class="transaction-info-row row d-flex">
                    <div
                      class="transaction-info-item transaction-info-item-account col-4"
                      v-for="(value, key) in order"
                      :key="key"
                    >
                      <div class="item-title text-capitalize">
                        {{ key.replaceAll('_', ' ') }}
                      </div>
                      <div class="item-value" v-if="key === 'payment_method' || key === 'shipping_method'">
                        {{ value.name }}
                      </div>
                      <div v-else-if="key === 'status'" class="item-value">
                        {{ value === 1 ? 'Active' : 'Inactive' }}
                      </div>
                      <div
                        v-else-if="
                          key === 'product_total_price' ||
                          key === 'payment_fee' ||
                          key === 'shipping_fee' ||
                          key === 'total'
                        "
                        class="item-value"
                      >
                        {{ formatPrice(value) }}
                      </div>
                      <div v-else class="item-value">
                        {{ value ? value : 'null' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ticket-item">
                <div class="ticket-detail">
                  <div class="ticket-name">
                    {{ editedItem.order_detail.game.name }}
                  </div>
                  <span class="ticket-price float-right correct"
                    >{{ formatPrice(editedItem.order_detail.price) }}&nbsp;<img style="position: relative; top: -2px"
                  /></span>
                  <span class="ticket-price float-right correct"
                    >{{ `Vé: ${editedItem.order_detail.ticket_name}` + ' | ' }}&nbsp;<img
                      style="position: relative; top: -2px"
                  /></span>
                </div>
                <div class="ticket-result d-flex flex-wrap">
                  {{ ticket_info_string }}
                  <div
                    :class="{
                      ticketItemListNumber: true
                    }"
                    v-for="(number, index) in ticket_info"
                    :key="index"
                  >
                    {{ number }}
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Reward List</h4>
      </b-col>
      <!-- filter_reward_amount -->
      <b-col>
        <b-form-group id="input-amount-filter" label="Reward Amount" label-for="amount-filter">
          <b-form-input
            trim
            id="amount-filter"
            v-model="filter_reward_amount"
            type="number"
            min="0"
            placeholder="Search price bigger receiver amount ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterGame -->
      <b-col>
        <b-form-group id="input-game-filter" label="Game" label-for="game-filter">
          <b-form-select id="game-filter" v-model="filter_game_id" :options="allGameList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col>
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(account)="item">
        {{ item.item.account.name }}
      </template>
      <template v-slot:cell(correct_slots)="item">
        {{ item.item.product_reward.correct_slots }}
      </template>
      <template v-slot:cell(jackpott)="item">
        {{ item.item.product_reward.jackpott }}
      </template>
      <template v-slot:cell(reward_amount)="item">
        {{ formatPrice(item.item.reward_amount) }}
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(orderDetail)="item">
        <v-icon class="text-success" @click="viewItem(item.item)">mdi-eye</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
// import { ticketStatus } from "@/common/global";
export default {
  name: 'Reward',
  components: {},
  data() {
    return {
      isLoading: true,
      search: '',
      isBusy: false,
      filter_reward_amount: '',
      filter_username: '',
      filter_game_id: null,
      filter_shipping_id: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Account', key: 'account' },
        { text: 'display_reward_id', key: 'display_reward_id' },
        { text: 'correct_slots', key: 'correct_slots' },
        { text: 'jackpott', key: 'jackpott' },
        { text: 'reward_amount', key: 'reward_amount' },
        // { text: "product_reward", key: "product_reward" },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Order Detail', key: 'orderDetail', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allGameList: [],
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        reward_amount: '',
        status: 1,
        display_reward_id: '',
        account: {
          id: '',
          name: ''
        },
        product_reward: {
          correct_slots: '',
          jackpott: ''
        },
        order_detail: {
          ticket_info: '',
          period_name: '',
          price: '',
          ticket_name: '',
          game: {
            name: ''
          },
          product: {
            name: ''
          },
          order: {
            created_at: '',
            status: '',
            receiver_name: '',
            receiver_phone: '',
            receiver_id: '',
            receiver_birth: '',
            product_total_price: '',
            payment_fee: '',
            shipping_fee: '',
            total: '',
            display_order_id: '',
            payment_method: {
              name: ''
            },
            shipping_method: {
              name: ''
            }
          }
        }
      },
      dialog: false,
      editedIndex: -1,
      orderDetail: [],
      isArr: false,
      ticket_info: null,
      ticket_info_string: '',
      order: null
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {},
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // {
      //   title: "Product",
      //   route: "product-list"
      // },
      { title: 'Reward List' }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsProduct = ApiService.get(
        'rewards',
        `?&game=${this.filter_game_id || ''}&reward_amount=${this.filter_reward_amount}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const allGame = ApiService.get('allgame');
      axios
        .all([adsProduct, allGame])
        .then(
          axios.spread((...response) => {
            const adsProductRes = response[0];
            const allGameRes = response[1];
            this.items = adsProductRes.data.data.rewardList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = adsProductRes.data.data.total;
            this.allGameList = allGameRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.modalTitle = `${item.order_detail.period_name}`;
      this.editedItem = {
        ...item
      };
      let ticketInfo = item.order_detail.ticket_info;
      this.order = item.order_detail.order;
      if (this.ticketStatus.includes(ticketInfo)) {
        this.ticket_info_string = ticketInfo;
      } else {
        this.ticket_info = JSON.parse(ticketInfo);
      }
    },

    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        reward_amount: '',
        status: '',
        display_reward_id: '',
        account: {
          id: '',
          name: ''
        },
        product_reward: {
          correct_slots: '',
          jackpott: ''
        },
        order_detail: {
          ticket_info: '',
          period_name: '',
          price: '',
          ticket_name: '',
          game: {
            name: ''
          },
          product: {
            name: ''
          },
          order: {
            created_at: '',
            status: '',
            receiver_name: '',
            receiver_phone: '',
            receiver_id: '',
            receiver_birth: '',
            product_total_price: '',
            payment_fee: '',
            shipping_fee: '',
            total: '',
            display_order_id: '',
            payment_method: {
              name: ''
            },
            shipping_method: {
              name: ''
            }
          }
        }
      };
      this.editedIndex = -1;
      this.filter_status = null;
      this.ticket_info = null;
      this.ticket_info_string = '';
      this.order = null;
    }
  }
};
</script>

<style lang="scss">
.oderDetail {
  .oderDetailBody {
    .oderDetailContent {
      .card {
        border: unset !important;
      }
    }
  }
}
</style>
